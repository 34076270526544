@font-face {
  font-family: VazirMedium;
  src: url('./fonts/Vazir-Medium.eot');
  src: url('./fonts/Vazir-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('./fonts/Vazir-Medium.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('./fonts/Vazir-Medium.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('./fonts/Vazir-Medium.ttf') format('truetype');
}

@font-face {
  font-family: VazirBlack;
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/Vazir-Black.eot');
  src: url('./fonts/Vazir-Black.eot?#iefix') format('embedded-opentype'), /* IE6-8 */
  url('./fonts/Vazir-Black.woff2') format('woff2'), /* FF39+,Chrome36+, Opera24+*/
  url('./fonts/Vazir-Black.woff') format('woff'), /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
  url('./fonts/Vazir-Black.ttf') format('truetype');
}
