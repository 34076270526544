.container__form {
  width: 500px;
  margin: 100px auto 0;
  display: block;
  min-height: 500px;
  height: auto;

  h2 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 85px;
    font-size: 2.125rem;
  }

  label {
    position: relative;
    margin-top: 10px;
  }

  input {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
  }

  button {
    border: 1px solid #eee;
    background-color: #2a7bbf;
    color: #fff;
    border-radius: 8px;
    margin-top: 15px;
    padding: 10px 35px;
    width: 100%;
  }

  button:disabled,
  button[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }



  .content__form-add {
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;

    .select_form-add {
      width: 100%;
    }

    input {
      width: 100% !important;
    }

    .text__validation{
      color: #e61238 !important;
      font-size: 10px;
      width: 100%;
      right: 0;
    }


    .icon__modal-plus{
      font-size: 23px;
      color: #555;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      top: 12px;
    }
  }

  .list-portfolio{
    display: flex;
    align-content: center;
    justify-content: space-between;
    p{
      width: 33%;
      &:nth-child(3){
        text-align: left;
      }
      &:nth-child(2){
        text-align: center;
      }
    }
  }
}

.add__portfolio{
  display: none;

}

@media (max-width: 700px) {
  .container__form{
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    .content__form-add {
      flex-direction: column;
      .select_form-add {
        width: 100%;
      }

      div{
        width: 100% !important;
      }
      input {
        width: 100% !important;
      }
    }

    .icon__modal-plus{
      top: 0;
    }
  }

  .icon__desktop{
    display: none !important;
  }
  .add__portfolio{
    display: block;
  }

}

._loading_overlay_overlay{
  border-radius: 12px;
  background: rgba(36, 54, 134, 0.6) !important;
  padding: 30px;
  span{
    margin: 0 auto !important;
    display: block;
  }
}
