@import './typography.scss';

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  direction: rtl !important;
  text-align: right !important;
  font-size: 1rem;
  line-height: 1.7;
  font-family: VazirMedium;
  background: #fff;
}

* {
  box-sizing: border-box;
}

button {
  font-family: VazirMedium;
  cursor: pointer;
}

a {
  text-decoration: none !important;
}

h1, h2, h3, h4, h5 {
  font-family: VazirBlack !important;
}
p{
  color: rgb(93, 95, 98);

}
.text-success {
  color: #16c784;
}

.text-pending {
  color: #fcb819;
}

.text-danger {
  color: #ff4530;
}

label {
  &.has-error {
    color: #e61238;
  }
}

.text__validation-mobile{
  color: #e61238 !important;
  font-size: 10px;
  position: absolute;
  bottom: -20px;
  width: 100%;
  right: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.send-message-successes-container{
  width: 100%;
  background-color: #f6ffed;
  border: 1px solid #b7eb8f;
  padding: 10px;
  font-size: 13px;
  color: #222;
  border-radius: 5px;
}

.send-message-error-container{
  width: 100%;
  background-color: #ffeded;
  border: 1px solid #eb8f8f;
  padding: 10px;
  font-size: 13px;
  color: #222;
  border-radius: 5px;
}

textarea, input{
  font-family: VazirMedium;
}
