.bg-contact-page-about {
  height: 110px;
  width: 100%;
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
}

.section__about {
  h2 {
    text-align: center;
    font-size: 2rem;
  }
  p{
    text-align: center;
  }
}

.container-contact-page {
  display: flex;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;

  .title__contact {
    text-align: right;
    margin: 0;
    line-height: 0;
  }

  .description__contact {
    text-align: right;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  img {
    width: 100%;
  }

  input {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 6px;
  }

  textarea {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 6px;
    max-width: 100%;
    min-width: 100%;
    max-height: 200px;
    min-height: 100px;
  }

  button {
    border: 1px solid #eee;
    background-color: #2a7bbf;
    color: #fff;
    border-radius: 8px;
    padding: 10px 35px;
  }

  p {
    text-align: justify;
    margin-top: 10px;
    direction: rtl;
  }

  h3 {
    text-align: justify;
    font-size: 2.125rem;
    margin: 0;
  }

  label {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-bottom: 5px;

    input {
      text-align: right;
      box-shadow: none !important;
    }

    span {
      color: #000;
      margin-bottom: 3px;
    }
  }
}

.about__text-bold {
  text-align: justify;
  font-weight: bold;
  margin-top: 0
}

.about__text-justify {
  text-align: justify;
}

.about__text-center {
  text-align: center;
}

.title__about {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 2rem;
}

.container__team-about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .content__box-team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 23%;
    margin-bottom: 50px;

    div {
      width: 55%;
    }

    p {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin: 0;
      line-height: 1.5;


      .anticon {
        margin-left: 15px;
        font-size: 18px;
        color: #2177bd;
      }
    }

    img {
      width: 180px;
      height: 180px;
      border-radius: 7%;
      filter: grayscale(100%);
      object-fit: cover;
    }
  }
}

.content__form-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;

  label {
    width: 48%;
    position: relative;
  }
}


.container__about-top {
  p {
    text-align: justify;
  }

  h3 {
    font-size: 2rem;
    text-align: right;
    margin: 0;
  }

  margin-top: 150px;
  padding: 0 70px;

}


@media (max-width: 600px) {

  .container__about-top {
    padding: 0 20px;
    margin-top: 100px;
  }

  .container__team-about {
    flex-direction: column;

    .content__box-team {
      width: 100%;
    }
  }
  .container-contact-page {
    padding-right: 20px;
    padding-left: 20px;

    div {
      &:nth-child(1) {
        display: none;
      }

      &:nth-child(2) {
        width: 100% !important;
        margin-right: 0 !important;

        .title__contact {
          margin-top: 100px;
        }
      }
    }

  }
}
