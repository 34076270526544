.content-header-nav-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 5%;
  padding-top: 70px;
  padding-bottom: 70px;

  button {
    margin: 20px auto 0;
    display: block;
  }

  img {
    width: 50%;
  }

  h2 {
    text-align: justify;
    margin: 0 auto 12px;
    width: 80%;
    font-size: 2.125rem;
  }

  .text-content-header {
    width: 80%;
    text-align: justify;
    margin: 0 auto;
    color: rgb(93, 95, 98);
  }
}


.container__services {
  h2 {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 85px;
    font-size: 2.125rem;
  }

  button {
    margin: 20px auto;
    display: block;
  }

  .content__services {
    display: flex;
    justify-content: space-between;
    padding: 0 70px;

    .services__box {
      display: flex;
      justify-content: flex-start;
      width: 40%;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      img {
        width: 35%;
      }

      h3 {
        text-align: center;
        margin: 10px;
        line-height: 1;
      }
      h2 {
        text-align: center;
        margin:0;
      }

      p {
        text-align: justify;
        margin: 0;
        line-height: 1.7;
      }
    }
  }

  &.bg__gray-content-text {
    p {
      width: 85% !important;
    }
  }
}

.bg__gray-content {
  background-color: #f8f8f8;
  padding: 10px;
  width: 98%;
  margin: 0 auto;
  border-radius: 55px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.circle_level {
  width: 90px;
  height: 90px;
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 23px;
}

.bg__pa {
  background-color: #253686;
}

.bg__green {
  background-color: #297abe;
}

.container__video{
  margin-bottom: 100px;
  margin-top: 100px;
  padding-left: 70px;
  padding-right: 70px
}


@media (max-width: 600px) {
  .container__video{
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    video{
      height: 400px !important;
    }
  }

  .content-header-nav-link{
    margin-top: 100px;
    img{
      display: none;
    }
    h2 {
      font-size: 1.5rem;
    }
  }
  .container__services {
    h2{
      font-size: 1.5rem;
    }
    .content__services {
      flex-direction: column;
      align-items: center;
      padding: 0;

      .services__box {
        width: 80%;
        img{
          width: 45%;
        }
      }
    }
  }

  .bg__gray-content {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
