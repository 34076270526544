.container__header {
  width: 100%;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0 0 5px #ccc;
  padding: 10px 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  top: 0;
  z-index: 999;

  .content__link-header {
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }

    a {
      margin-right: 15px;
      margin-left: 15px;
      color: #000;
      font-weight: 500;
    }
  }
}

.container-Footer {
  line-height: 2;
  padding: 25px 70px;
  background-color: rgb(248, 248, 248);
  color: rgba(0, 0, 0, 0.87);
  min-height: 200px;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .content {
    width: 30%;
    font-weight: 300;
    a{
      color: rgba(0, 0, 0, 0.87);
      img {
        width: 5%;
        margin-left: 10px;
        margin-top: 15px;
      }
    }

  }
}

.Bottom-Footer {
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  justify-content: space-between;
  background-color: rgb(248, 248, 248);
  padding: 20px 70px;

  p {
    color: rgba(0, 0, 0, 0.87);
  }
}

.text-warning-modal {
  padding: 10px;
  background-color: #fffbe6;
  border: 1px solid #ffe58f;
  font-size: 12px;
}

.container__form-modal {
  display: flex;
  flex-direction: column;
  padding: 20px;

  label {
    position: relative;
    margin-top: 10px;
  }

  input {
    border: 1px solid #eee;
    padding: 10px;
    border-radius: 6px;
    width: 100%;
  }

  button {
    border: 1px solid #eee;
    background-color: #2a7bbf;
    color: #fff;
    border-radius: 8px;
    margin-top: 15px;
    padding: 10px 35px;
  }
}


@media (max-width: 600px) {
  .container-Footer {
    flex-direction: column;
    padding: 20px;
    .content {
      width: 100%;
      a{
        img {
          width: 10%;
        }
      }
    }
  }
  .container__header {
    padding: 10px 10px;
     .content__link-header {
       a {
         margin-right: 5px;
         margin-left: 5px;
         font-size: 8px;
       }
     }
  }
  .Bottom-Footer {
    padding: 20px 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
