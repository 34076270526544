.show__modal-form {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  background-color: #2a7bbf;
  color: #fff;
}

.icon__modal-close {
  font-size: 23px;
  color: #555;
  cursor: pointer;
}

@media (max-width: 600px) {
  .show__modal-form {
    padding: 10px 7px !important;
    font-size: 10px;
    width: 40%;
  }
}
